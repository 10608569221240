<template>
  <getConnectionObject
    sentUrl="serve.php?f=configuration&f2=inboundConnections&f3=remoteClients&f4=nocsRemoteClients"
    tableID="nocsRemoteClientsDataTable"
    :result="result"
    :fieldAttributes="fieldAttributes"
    :field="field"
    :updatedDialogRef="updatedDialogRef"
     :value="value"
  ></getConnectionObject>
</template>
<script>
import getConnectionObject from "@/components/legacy/getConnectionObject.vue";
export default {
  components: { getConnectionObject },
  props: ["result", "fieldAttributes", "field", "updatedDialogRef","value"],
};
</script>